import React from "react";
import { MediaFeature } from "./MediaFeature";
import IntegrationVideoMP4 from "../../../images/demo/integrations.mp4";

import MacVideoMP4 from "../../../images/demo/mac-cc.mp4";
import MacVideoWEBM from "../../../images/demo/mac-cc.webm";
import WindowsVideoMP4 from "../../../images/demo/windows-cc.mp4";
import WindowsVideoWEBM from "../../../images/demo/windows-cc.webm";

import IntegrationVideo from "../../../images/demo/integrations.webm";
import { AvailableInChromeBox } from "../../common/AvailableInChromeBox";
import { useIsMacOS } from "../../../utils";
import { useMemo } from "react";
import { detect } from "detect-browser";

export const WindowsCCVideo: React.FC = () => {
  return (
    <video
      autoPlay
      playsInline
      muted
      loop
      preload="auto"
      className="xl:h-128 lg:h-80 rounded-DEFAULT"
    >
      <source src={WindowsVideoWEBM} type="video/webm" />
      <source src={WindowsVideoMP4} type="video/mp4" />
    </video>
  );
};

const MacCCVideo: React.FC = () => {
  return (
    <video
      autoPlay
      playsInline
      muted
      loop
      preload="auto"
      className="xl:h-128 lg:h-80 rounded-DEFAULT"
    >
      <source src={MacVideoWEBM} type="video/webm" />
      <source src={MacVideoMP4} type="video/mp4" />
    </video>
  );
};

export const ChromeFeature: React.FC<{
  showCaption?: boolean;
  sideOverride?: "left" | "right";
}> = ({ showCaption = true, sideOverride = "left" }) => {
  const isMacOS = useMemo(() => useIsMacOS(), [detect()]);

  return (
    <MediaFeature
      caption={showCaption && "🔍 Want to learn something?"}
      header={
        <>
          <span className="text-purple-600">Create flashcards in seconds</span>
          <br />
          <div className="flex flex-row">
            <span>from any website or PDF</span>
          </div>
        </>
      }
      body={
        <>
          The Zorbi Chrome extension makes it easy to create flashcards while
          you're watching lectures, reading notes, or summarising a textbook.
          <br />
          <br />
          <span className="leading-loose">
            Try it out - you'll wish you found Zorbi earlier.
          </span>
          <br />
          <br />
          <AvailableInChromeBox variant="default" />
        </>
      }
      media={isMacOS ? <MacCCVideo /> : <WindowsCCVideo />}
      mediaSide={sideOverride}
    />
  );
};
