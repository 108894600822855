import tw from "twin.macro";
import React from "react";

const FeatureHeader = tw.h2`text-2xl md:text-3xl mb-4 font-bold`;
const FeatureBody = tw.div`text-blueGray-600 leading-loose mb-6`;

const FeatureHeaderCaption = tw.div`font-semibold text-blueGray-500 leading-none block mb-2 text-base`;

export type MediaSide = "left" | "right"

interface MediaFeatureProps {
  media: JSX.Element;
  caption?: string;
  header: JSX.Element;
  body: JSX.Element;
  disableMediaShadow?: boolean;
  mediaSide: MediaSide;
}

const RightFeature: React.FC<Exclude<MediaFeatureProps, "mediaSide">> = (
  props
) => {
  return (
    <section className="pt-20 overflow-x-hidden ">
      <div className="flex flex-wrap">
        <div className="flex order-1 w-full px-4 lg:order-0 lg:w-1/2 lg:h-128 lg:max-w-2xl lg:ml-auto lg:pr-24 xl:pr-4">
          <div className="max-w-2xl pt-8 mx-auto my-auto lg:pt-0 lg:max-w-lg">
            {props.caption && (
              <FeatureHeaderCaption>{props.caption}</FeatureHeaderCaption>
            )}
            <FeatureHeader>{props.header}</FeatureHeader>
            <FeatureBody>{props.body}</FeatureBody>
          </div>
        </div>
        <div className="relative items-center w-full px-4 order-0 lg:order-1 lg:w-1/2 lg:h-128">
          <div
            className={`w-full flex relative top-0 left-0 h-full mx-auto bg-transparent lg:absolute lg:max-w-6xl lg:-mr-64 ${
              !props.disableMediaShadow ? "shadow-xl" : ""
            }`}
          >
            {props.media}
          </div>
        </div>
      </div>
    </section>
  );
};

const LeftFeature: React.FC<MediaFeatureProps> = (props) => {
  return (
    <section className="pt-20 overflow-x-hidden">
      <div className="flex flex-wrap">
        <div className="relative w-full px-4 lg:w-1/2 lg:h-128">
          <div
            className={`relative flex justify-center top-0 right-0 mx-auto lg:absolute lg:max-w-6xl lg:-ml-64 ${
              !props.disableMediaShadow ? "shadow-xl" : ""
            }`}
          >
            {props.media}
          </div>
        </div>
        <div className="flex items-center w-full px-4 lg:w-1/2 lg:max-w-2xl lg:pl-16">
          <div className="max-w-2xl pt-8 mx-auto my-auto lg:pt-0 lg:max-w-2xl">
            {props.caption && (
              <FeatureHeaderCaption>{props.caption}</FeatureHeaderCaption>
            )}
            <FeatureHeader>{props.header}</FeatureHeader>
            <FeatureBody>{props.body}</FeatureBody>
          </div>
        </div>
      </div>
    </section>
  );
};

export const MediaFeature: React.FC<MediaFeatureProps> = (props) => {
  return props.mediaSide == "left" ? (
    <LeftFeature {...props} />
  ) : (
    <RightFeature {...props} />
  );
};
