import React from "react";

import { ChromeFeature } from "../sections/features/ChromeFeature";
import Typist from "react-typist";
import TypistLoop from "react-typist-loop";

import { IntegrationPage } from "./IntegrationPage";
import { StaticImage } from "gatsby-plugin-image";

const useCases = [
  "watching lectures",
  "reading a textbook",
  "watching YouTube",
  "learning on Udemy",
  "browsing Twitter",
];

export const ChromeIntegrationPage: React.FC = () => {
  return (
    <IntegrationPage
      title={
        <span>
          Create flashcards while{" "}
          <TypistLoop interval={1000}>
            {useCases.map((text) => (
              <Typist key={text} startDelay={500} avgTypingDelay={30}>
                {text}
              </Typist>
            ))}
          </TypistLoop>
        </span>
      }
      headerImage={
        <StaticImage
          src={"../../images/chrome.png"}
          className="w-20 h-20 mb-4"
          placeholder="blurred"
          alt="Chrome Logo"
        />
      }
    >
      <div className="bg-blueGray-50">
        {/*TODO: Modify this feature so it very clearly says "Highlight text, right click, turn it into a flashcard."*/}
        <ChromeFeature showCaption={false} />
      </div>
    </IntegrationPage>
  );
};
