import React from "react";
import Layout from "../components/common/layout/layout";
import { Navigation } from "../components/common/navigation/navigation";
import { ChromeIntegrationPage } from "../components/pages/ChromeIntegrationPage";
import { Footer } from "../components/sections/footer";
import { chromeFlashcards } from "../utils/seoText";
import SEO from "../components/common/layout/seo";

const MakeFlashcardsInNotionPage = () => {
  return (
    <Layout>
      <SEO {...chromeFlashcards} />
      <Navigation />
      <ChromeIntegrationPage />
      <Footer />
    </Layout>
  );
};

export default MakeFlashcardsInNotionPage;
