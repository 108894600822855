import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const AvailableInChromeBox: React.FC<{ variant: "white" | "default" }> =
  ({ variant }) => {
    return (
      <a
        target="_blank"
        className="inline-block w-64 border-2 border-purple-200 hover:bg-purple-200 hover:border-purple-300 rshadow-xlounded-DEFAULT"
        href="https://chrome.google.com/webstore/detail/zorbi-flashcard-creator/cionjeaneeijknieabfgfiidhhjlkbcm"
      >
        {variant == "default" ? (
          <StaticImage
            alt="Available in Chrome Store"
            src={"../../images/availableinchrome.png"}
            placeholder="blurred"
          />
        ) : (
          <StaticImage
            placeholder="blurred"
            alt="Available in Chrome Store"
            src={"../../images/availableinchrome_colorbg.png"}
          />
        )}
      </a>
    );
  };
